import MetaTags from "../../components/reusable/ReactMetaTags";
import DefaultContainer from "../../components/generic/default-container";
import LayoutStore from "../../hooks/store/client/layout-store";
import { Grid, Box, Typography, Link, Button } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import APP_ENV from "../../appEnvironment";
import { useLocationStore } from "../../hooks/store/location-store";
import useAppCreditInformation from "../../hooks/store/credit-store";

const { theme } = APP_ENV;

const SyncronyCredit = () => {
  const [isMobile] = LayoutStore((st) => [st.isMobile]);

  const [creditPromotion] = useLocationStore((st) => [
    st.userStore?.promotion?.credit,
  ]);

  const APP_CREDIT = useAppCreditInformation();

  return (
    <>
      <MetaTags
        description={`${APP_ENV.appBrandName} offers industry leading financing options. Apply online today!`}
        canonical={APP_CREDIT.creditPageEndPoint}
        title={`Credit Card | ${APP_ENV.appBrandName}`}
      />
      <DefaultContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 25,
          }}
        >
          <img
            src={APP_CREDIT?.mainImage}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              maxHeight: 275,
            }}
          />
        </Box>

        {creditPromotion?.currentRebate && (
          <Box
            style={{
              textAlign: "center",
              borderRadius: 8,
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 10,
              border: `2px solid ${theme.palette.error.main}`,
              marginBottom: 15,
            }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              {`Get 15% Back with a Purchase of $199 or more (Up to $150)`}
              <sup>*</sup>
            </Typography>
            <Typography variant="caption">
              {`Get 15% back (up to $150) via Visa® Prepaid Card by mail with a purchase of $199 or more made with your ${APP_CREDIT.creditCardName} credit card at
           a participating ${APP_ENV.appBrandName} location. Offer valid on purcahses made 1/1/2024-2/29/2024. Rebate form must be submitted online 
           or by mail postmarked by 4/1/2024.`}
            </Typography>
            <Box style={{ marginTop: 15 }}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{
                  height: 50,
                  color: theme.palette.secondary.contrastText,
                  maxWidth: 300,
                }}
                component={Link}
                href={creditPromotion?.currentRebate}
                target="_blank"
              >
                Print Rebate Form
              </Button>
            </Box>
          </Box>
        )}

        <Box
          style={{
            textAlign: "center",
            borderRadius: 8,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
            border: `2px solid ${theme.palette.error.main}`,
          }}
        >
          <Typography variant="h4" style={{ fontWeight: "bold" }} gutterBottom>
            No Interest if Paid in Full Within 6 Months
            <sup>**</sup>
          </Typography>
          <Typography variant="caption">
            {`On purchases of $199 or more made with your ${APP_CREDIT.creditCardName} credit card. 
             Interest will be charged to your account from the purchase date if the
             promotional balance is not paid in full within 6 months.
             Minimum monthly payments required.`}
          </Typography>
        </Box>
        <Box style={{ textAlign: "center", padding: 20 }}>
          <Typography
            variant="h5"
            style={{ color: theme.palette.error.main, fontWeight: "bold" }}
          >
            EXTRAORDINARY BENEFITS
          </Typography>
          <Typography variant="caption" style={{ fontWeight: "bold" }}>
            {`Additional Features of the ${APP_CREDIT.creditCardName} credit card:`}
          </Typography>
        </Box>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Grid
            container
            style={{
              width: "100%",
              maxWidth: 1000,
              display: "flex",
              justifyContent: isMobile ? "center" : "space-around",
            }}
          >
            {[
              [
                <>
                  Accepted at auto merchant locations nationwide<sup>1</sup>
                </>,
                <>
                  Accepted at Gas Stations nationwide<sup>1</sup>
                </>,
                <>Credit decision within minutes</>,
                <>Convenient Monthly Payments</>,
                <>
                  Estimate monthly payments{" "}
                  <Link
                    style={{ color: theme.palette.error.main }}
                    href={APP_CREDIT.estimateMonthlyPaymentLink}
                    rel="noopener"
                    target="_blank"
                  >
                    here
                  </Link>
                </>,
              ],
              [
                <>Accepted at auto merchant locations nationwide</>,
                <>
                  No annual fee<sup>2</sup>
                </>,
                <>$0 fraud liability</>,
                <>Exclusive cardholder offers</>,
                <>
                  Account access on the MySynchrony mobile app<sup>3</sup>
                </>,
              ],
            ].map((group, idx) => {
              return (
                <Grid item xs={12} sm={8} md={5} lg={5} xl={5} key={idx}>
                  {group.map((item, i) => {
                    return (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: 7,
                        }}
                        key={i}
                      >
                        <FiberManualRecordIcon
                          style={{ fontSize: 10, marginRight: 8 }}
                        />
                        <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                          {item}
                        </Typography>
                      </Box>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: 30, paddingBottom: 30 }}>
          {[
            [
              "SEE IF YOU PREQUALIFY",
              "Get a decision in seconds with no impact on your credit bureau score.",
              "SEE IF YOU PREQUALIFY",
              APP_CREDIT.preQualifyLink,
            ],
            [
              "READY TO APPLY?",
              "Get started here. The application and credit decision happens within minutes.",
              "APPLY NOW",
              APP_CREDIT.applyNowLink,
            ],
          ].map(([title, subtext, buttonLabel, href], i) => {
            return (
              <Grid item xs={12} md={6} style={{ textAlign: "center" }} key={i}>
                <Typography
                  variant="h5"
                  style={{
                    color: i == 0 ? theme.palette.primary.main : "default",
                  }}
                  gutterBottom
                >
                  {title}
                </Typography>
                <Typography style={{ fontSize: 12, fontWeight: 600 }}>
                  {subtext}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    margin: 25,
                    color: theme.palette.primary.contrastText,
                    height: 50,
                  }}
                  component={Link}
                  href={href}
                  rel="noopener"
                  target="_blank"
                >
                  {buttonLabel}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          container
          style={{
            marginBottom: 50,
            padding: 20,
            textAlign: "center",
            backgroundColor: theme.palette.footer2.main,
          }}
        >
          <Box
            style={{
              width: "100%",
              textAlign: "center",
              color: theme.palette.primary.contrastText,
            }}
          >
            <Typography variant="h6">
              Manage your account with Synchrony
            </Typography>
            <Typography style={{ fontSize: 14 }}>
              Make payments, view your balance, update account information and
              schedule up to 12 payments at a time.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: 25,
                color: theme.palette.primary.contrastText,
                height: 50,
              }}
              component={Link}
              href={APP_CREDIT.manageAccountLink}
              rel="noopener"
              target="_blank"
            >
              Manage My Account
            </Button>
          </Box>
        </Grid>
        <Grid
          container
          style={{ marginTop: 50, marginBottom: 50, padding: 10 }}
        >
          {creditPromotion?.currentRebate && (
            <Typography style={{ marginBottom: 20, fontSize: 14 }}>
              *Subject to credit approval. Rebate in the form of a Synchrony
              Visa Prepaid Card by mail. One rebate per qualifying purchase
              valid at participating U.S. locations. The Visa Prepaid Card will
              be sent to your U.S. mailing address within 6 to 8 weeks after
              receipt of the completed rebate form. If the Visa Prepaid Card is
              not received after 8 weeks, call 1-866-419-4001 or visit
              synchronycarcare.sendmyrewards.com to inquire about status. If the
              Visa Prepaid Card is not received, you must inquire no later than
              6/13/2024 or all rights hereunder will expire. See rebate form or
              store for details. This offer is void where prohibited. Synchrony
              Bank is not responsible for lost, damaged, illegible, postage due
              or delayed mail.
              <br />
              Synchrony Car Care Visa Prepaid Card is issued by Pathward, N.A.,
              Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash
              access or recurring payments. Can be used everywhere Visa debit
              cards are accepted. The funds associated to this Card expire 6
              months from the activation date. After the expiration date, you
              will not be able to use this Card, and any remaining Card funds
              will revert and be returned to the promotional provider. Card
              terms and conditions apply. The Big Brand Tire & Service credit
              card is not a Pathward product or service nor does Pathward
              endorse this offer.
            </Typography>
          )}

          <Typography style={{ marginBottom: 20, fontSize: 14 }}>
            ** A Promo Fee does not apply to this offer. Qualifying purchase
            amount must be on one receipt. No interest will be charged on the
            promo balance if you pay it off, in full, within the promo period.
            If you do not, interest will be charged on the promo balance from
            the purchase date. The required minimum monthly payments may or may
            not pay off the promo balance before the end of the promo period,
            depending on purchase amount, promo length and payment allocation.
            Regular account terms apply to non-promo purchases and, after promo
            period ends, to the promo balance. New Accounts as of 7/16/24:
            Purchase APR 34.99%. Penalty APR 39.99%. Min Interest Charge $2. A
            promo fee will be charged equal to 2% of the amount financed on an
            equal payment no interest promotion of 18 months or more. Existing
            cardholders: See your credit card agreement terms. Subject to credit
            approval. Gas station purchases are not eligible for Promotional
            Financing.
          </Typography>
          <Box style={{ marginTop: 20, marginBottom: 40 }}>
            <Typography gutterBottom style={{ fontSize: 14 }}>
              <sup>1</sup>Subject to credit approval. Valid everywhere Synchrony
              Car Care™ is accepted in the U.S., including Puerto Rico.
            </Typography>
            <Link
              style={{ marginLeft: 10 }}
              href="https://www.mysynchrony.com/carcare/locator.html"
              target="_blank"
              rel="noopener"
            >
              Click here for merchant locations
            </Link>
            <Typography style={{ fontSize: 14 }}>
              <sup>3</sup>Data fees may apply.
            </Typography>
            <Box style={{ marginTop: 10 }}>
              <Link target="_blank" href={APP_CREDIT.prevRebate}>
                {`Print Previous Rebate Form (Ended Feb 29, 2024)`}
              </Link>
            </Box>
          </Box>
        </Grid>
      </DefaultContainer>
    </>
  );
};

export default SyncronyCredit;
