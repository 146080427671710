import { Box, Collapse, Grid, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import useZState from "../../../hooks/util/useZState";
import { CART_ITEM_TYPE, useCartStore } from "../../../hooks/store/cart-store";
import {
  STATIC_CART_ITEMS,
  createBasicCartItem,
} from "../../../static/cart-items";
import { TIRES_ROUTE_DIRECTORY } from "../../../routes";
import StyledInfoCard from "../widgets/styled-info-card";
import StyledSelectCard from "../widgets/styled-select-card";
import APP_ENV from "../../../appEnvironment";
import { useLocationStore } from "../../../hooks/store/location-store";
import ServiceCouponCallOut from "../../promotions/widgets/service-coupon-call-out";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { PROMO_THEME } from "../../../hooks/store/promotion-store";

const { theme } = APP_ENV;

const QuickSelectServicesCollection = ({ onSelect = () => null }) => {
  const {
    TIRE_ROTATION,
    FLAT_TIRE_REPAIR,
    WHEEL_ALIGNMENT_CHECK,
    BRAKE_CHECK,
    VEHICLE_FLIUD_CHECK,
    BATTERY_CHECK,
    ENGINE_CHECK,
    COOLING_SYSTEM_CHECK,
    TIRE_CONSULTATION,
    OIL_CONSULTATION,
    COMPREHENSIVE_VEHICLE_INSPECTION,
    SMOG_CHECK_NEVADA,
  } = STATIC_CART_ITEMS;

  const [hasTiresInCart] = useCartStore((st) => [st.hasTiresinCart]);

  const [userStore] = useLocationStore((st) => [st.userStore]);

  const history = useHistory();

  const [getState, setState] = useZState({
    isOpen: false,
    openIndex: 0,
  });

  const state = getState();

  const handleButtonClick = (index) => {
    let { isOpen, openIndex } = getState();

    setState({
      isOpen: isOpen && openIndex == index ? !isOpen : true,
      openIndex: index,
    });
  };

  const handleCartItemClick = (item) => {
    const itemAlreadyInCart = item.itemIsInCart();

    !itemAlreadyInCart ? item.addToCart(false) : item.removeFromCart();

    if (!itemAlreadyInCart) {
      onSelect();
    }

    setState({ isOpen: false });
  };

  let tireConsultPromo = {};

  if (userStore?.promotion && !tireConsultPromo?.promotion) {
    tireConsultPromo = {
      promotion: {
        itemName: `${userStore.promotion.displayText.tirePromoBox} - On Select Tire Brands`,
      },
    };
  }

    const filteredCartItems = [
        TIRE_ROTATION,
        FLAT_TIRE_REPAIR,
        WHEEL_ALIGNMENT_CHECK,
        BRAKE_CHECK,
        VEHICLE_FLIUD_CHECK,
        BATTERY_CHECK,
        ENGINE_CHECK,
        COOLING_SYSTEM_CHECK,
    ]
        .filter(item => item?.display);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Box style={{ width: "100%", textAlign: "center" }}>
          <span style={{ display: "flex" }}>
            <MonetizationOnIcon
              style={{
                color: "#ed0000",
                fontSize: 20,
              }}
            />
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginLeft: 5 }}
            >
              {`Don't miss out - ${PROMO_THEME.sitePromoName} Ending Soon!`}
            </Typography>
          </span>
        </Box>
      </Grid>
      {[
        [
          "Tires",
          "https://venomstorage.blob.core.windows.net/bbtwebblob/publicwebsitereactv1/images%20-%20homepage/ico-tires-duo.svg",
          hasTiresInCart,
          tireConsultPromo?.promotion,
        ],
        [
          "Oil Change",
          OIL_CONSULTATION?.image,
          null,
          OIL_CONSULTATION?.promotion,
        ],
      ]
        .filter((_, index) => index == 0 || APP_ENV.appBrandId !== 4)
        .map(([label, image, isSelected, promotion], i) => {
          return (
            <Grid item xs={6} key={i}>
              <StyledSelectCard
                isSelected={
                  isSelected || (state.isOpen && state.openIndex == i)
                }
                onSelect={() => handleButtonClick(i)}
                styleProps={{
                  justifyContent: "center",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                {image && <img src={image} width={50} height={40} />}
                <Typography variant="h4">{label}</Typography>
                {promotion && (
                  <Box style={{ marginTop: 5 }}>
                    <ServiceCouponCallOut promotion={promotion} />
                  </Box>
                )}
              </StyledSelectCard>
            </Grid>
          );
        })}

      <Collapse in={state.isOpen}>
        <Grid item xs={12}>
          {[
            [
              TIRE_CONSULTATION,
              "Shop Tires Now",
              "We have 1000's of tires on sale right now.",
              TIRES_ROUTE_DIRECTORY,
            ],
            [
              OIL_CONSULTATION,
              "Shop Oil Change Now",
              "Find the right oil for your vehicle online now.",
              OIL_CONSULTATION?.endPoint,
            ],
          ]
            .filter(
              ([item]) => item?.display
            )
            .map(([item, buttonLabel, actionDesc, endPoint], i) => {
              return (
                <Box
                  style={{ marginTop: 15, marginBottom: 15, width: "100%" }}
                  key={i}
                >
                  <StyledInfoCard
                    backgroundColor={theme.palette.background.paper}
                  >
                    <Typography style={{ marginBottom: 15 }}>
                      {actionDesc}
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box style={{ width: "100%", maxWidth: 300 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{ marginBottom: 15 }}
                          onClick={() => {
                            history.push(endPoint);
                          }}
                        >
                          {buttonLabel}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          style={{ marginBottom: 15 }}
                          onClick={() =>
                            handleCartItemClick({
                              ...item,
                              itemIsInCart: () => false,
                            })
                          }
                        >
                          {`I want a ${item?.itemName}`}
                        </Button>
                      </Box>
                    </Box>
                    <StyledInfoCard>
                      <Typography
                        style={{ fontWeight: 600 }}
                      >{`What is a ${item?.itemName}?`}</Typography>
                      <Typography>
                        {item?.itemDescription ?? "No description available"}
                      </Typography>
                    </StyledInfoCard>
                  </StyledInfoCard>
                </Box>
              );
            })}
        </Grid>
      </Collapse>

      {[
        COMPREHENSIVE_VEHICLE_INSPECTION,
        { ...TIRE_CONSULTATION, ...tireConsultPromo },
        OIL_CONSULTATION,
        userStore?.isSmogCheckStore && SMOG_CHECK_NEVADA,
      ]
        .filter((item) =>item?.display)
        .filter((i) => i)
        .map((item, i) => {
          return (
            <Grid item xs={12} key={i}>
              <StyledSelectCard
                isSelected={item?.itemIsInCart?.()}
                onSelect={() => handleCartItemClick(item)}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Box style={{ height: 50, maxWidth: 40 }}>
                    {item?.image && (
                      <img
                        src={item.image}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </Box>
                  <Box style={{ marginLeft: 12 }}>
                    <Typography
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {item?.itemName}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      {item?.itemDescription ?? "No description available"}
                    </Typography>
                    <ServiceCouponCallOut {...item} />
                  </Box>
                </Box>
              </StyledSelectCard>
            </Grid>
          );
        })}

      {filteredCartItems.map((item, i) => {
        return (
          <Grid item xs={6} key={i}>
            <StyledSelectCard
              isSelected={item?.itemIsInCart?.()}
              onSelect={() => handleCartItemClick(item)}
            >
              <Box
                style={{ display: "flex", alignItems: "center", height: 70 }}
              >
                <Box style={{ height: 50, maxWidth: 40 }}>
                  {item?.image && (
                    <img
                      src={item.image}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </Box>
                <Box style={{ marginLeft: 12 }}>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: theme.palette.error.main,
                    }}
                  >
                    Free
                  </Typography>
                  <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                    {item?.itemName}
                  </Typography>
                </Box>
              </Box>
              <ServiceCouponCallOut {...item} />
            </StyledSelectCard>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default QuickSelectServicesCollection;
