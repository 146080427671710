import create from "zustand";
import { STATIC_CART_ITEMS, EDGE_ITEM_IDS } from "../../static/cart-items";
import LocationStore, { useLocationStore } from "./location-store";
import AppointmentStore, { WEB_APPOINTMENT_TYPES } from "./appointment-store";
import UserInfoStore from "./user-info-store";
import CLIENT_STORAGE from "./client/storage/client-storage";
import TireItemsStore from "./tire-items-store";
import { roundMoney } from "../../helpers/generic";
import APP_ENV from "../../appEnvironment";
import dayjs from "dayjs";
import { VYPER_FREE_OIL_CHANGE } from "../../components/promotions/vyper/vyper-free-oil-change";
import { VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE } from "../../components/promotions/vyper/vyper-fifty-percent-off-oil-change";
import SMOG_CHECK_CONFIG from "../../static/smog-check-config";
import GoogleEventsStore from "./client/google-events-store";
import { useEffect } from "react";
import { VYPER_ESSENTIAL_PERSONNEL_DISCOUNT } from "../../components/promotions/vyper/vyper-essential-personnel-discount";
import SessionTimerStore from "./client/session-timer-store";

export const CART_ITEM_TYPE = {
  tire: 1,
  consultation: 2,
  freeService: 3,
  promotion: 4,
  smogCheck: 5,
};

let _promise;
let _sessionId;
let _storeNumber;

const CartStore = create((set, get) => {
  const initialState = {
    isInitialized: false,
    isReady: false,
    packageSubtotal: 0,
    totalDiscountsAvailable: 0,
    instantDiscountTotal: 0,
    mailInRebateTotal: 0,
    mailInRebates: [],
    subtotal: 0,
    taxAndFeesTotal: 0,
    tax: 0,
    stfTotal: 0,
    fetTotal: 0,
    invoicePrice: 0,
    cartItems: [],
    hasTiresinCart: false,
    isInventoryInStock: true,
    isInventoryAvailable: true,
    inventoryArrivesDateTime: null,
    inventoryArrivesDateTimeDisplay: null,
    requiresSpecialOrder: false,
  };

  const getCartStoreAsync = async () => {
    const sessionId = SessionTimerStore.getState().sessionId;

    const userStore = (await LocationStore.getState().getLocationStoreAsync())
      .userStore;

    const { storeNumber } = userStore;

    if (_sessionId !== sessionId || _storeNumber !== storeNumber) {
      _sessionId = sessionId;
      _storeNumber = storeNumber;
      set({ isReady: false });
    }

    const _state = get();

    if (!_state.isReady && !_promise) {
      _promise = (async () => {
        const cartItems =
          (_state.isInitialized
            ? get().cartItems
            : CLIENT_STORAGE.cart.getItem()) || [];

        if (_state.isInitialized) {
          set(initialState);
        }

        const addToCartFuncs = (
          await Promise.allSettled(
            cartItems.map((item) => {
              return (async () => {
                let addToCart;
                const { cartItemTypeId = null } = item;
                switch (true) {
                  case !cartItemTypeId:
                    break;
                  case cartItemTypeId == CART_ITEM_TYPE.tire:
                    if (
                      item?.brandEndPoint &&
                      item?.partNumberEndPoint &&
                      item?._cartQty
                    ) {
                      const tires =
                        await TireItemsStore.getState().getActiveTireItems(
                          item
                        );
                      addToCart = tires?.[0]?.qtyController(
                        item._cartQty
                      )?.addToCart;
                    }
                    break;
                  case cartItemTypeId == CART_ITEM_TYPE.promotion:
                    addToCart = [
                      [
                        STATIC_CART_ITEMS.FREE_OIL_CHANGE,
                        VYPER_FREE_OIL_CHANGE,
                      ],
                      [
                        STATIC_CART_ITEMS.FIFTY_PERCENT_OFF_OIL_CHANGE,
                        VYPER_FIFTY_PERCENT_OFF_OIL_CHANGE,
                      ],
                      [
                        STATIC_CART_ITEMS.ESSENTIAL_PERSONNEL_DISCOUNT,
                        VYPER_ESSENTIAL_PERSONNEL_DISCOUNT,
                      ],
                    ].find(([promoCartItem, promo]) => {
                      return (
                        promoCartItem.webUniqueItemId ==
                          item?.webUniqueItemId &&
                        promo.activePromotions
                          .map(({ stores }) => stores)
                          .flat()
                          .includes(storeNumber)
                      );
                    })?.[0]?.addToCart;

                    break;
                  case cartItemTypeId == CART_ITEM_TYPE.smogCheck:
                    addToCart =
                      userStore.smogCheck.isSmogCheckStore &&
                      STATIC_CART_ITEMS.SMOG_CHECK_NEVADA.addToCart;
                    break;
                  default:
                    addToCart = Object.values(STATIC_CART_ITEMS).find(
                      (i) => i.webUniqueItemId == item?.webUniqueItemId
                    )?.addToCart;
                }

                return addToCart;
              })();
            })
          )
        )
          .filter(({ value }) => value)
          .map(({ value }) => value);

        await new Promise((resolve) => {
          const handleAddToCart = async (index = 0) => {
            const addToCart = addToCartFuncs?.[index];
            if (addToCart) {
              await addToCart(false);
              handleAddToCart(index + 1);
            } else {
              resolve();
            }
          };
          handleAddToCart();
        });

        set({
          isInitialized: true,
          isReady: true,
        });

        _promise = null;
        return;
      })();
    }

    if (!_state.isReady) {
      await _promise;
    }

    return get();
  };

  const setCartItems = async (incomingCartItems = []) => {
    const { userStore } =
      await LocationStore.getState().getLocationStoreAsync();

    let packageSubtotal = 0;
    let totalDiscountsAvailable = 0;
    let instantDiscountTotal = 0;
    let mailInRebateTotal = 0;
    let mailInRebates = [];
    let subtotal = 0;
    let tax = 0;
    let stfTotal = 0;
    let fetTotal = 0;
    let invoicePrice = 0;
    let inventoryArrivesDateTime = userStore.currentDateTime;

    const cartItems = incomingCartItems.map((item) => {
      const cartItem = {
        brandEndPoint: null,
        partNumberEndPoint: null,
        webUniqueItemId: null,
        _cartQty: 1,
        _packageSubtotal: 0,
        _totalDiscountsAvailable: 0,
        _instantRebateDiscountsTotal: 0,
        _mailInRebateTotal: 0,
        _subtotal: 0,
        _tax: 0,
        _stfTotal: 0,
        _fetTotal: 0,
        _invoicePrice: 0,
        _qtyIsAvailable: true,
        _qtyIsInStock: true,
        _arrivesDateTime: userStore.currentDateTime,
        _mailInRebates: [],
        ...item,
      };

      packageSubtotal = roundMoney(packageSubtotal + cartItem._packageSubtotal);
      totalDiscountsAvailable = roundMoney(
        totalDiscountsAvailable + cartItem._totalDiscountsAvailable
      );
      instantDiscountTotal = roundMoney(
        instantDiscountTotal + cartItem._instantRebateDiscountsTotal
      );
      mailInRebateTotal = roundMoney(
        mailInRebateTotal + cartItem._mailInRebateTotal
      );
      subtotal = roundMoney(subtotal + cartItem._subtotal);
      tax = roundMoney(tax + cartItem._tax);
      stfTotal = roundMoney(stfTotal + cartItem._stfTotal);
      fetTotal = roundMoney(fetTotal + cartItem._fetTotal);
      invoicePrice = roundMoney(invoicePrice + cartItem._invoicePrice);

      mailInRebates = [...mailInRebates, ...cartItem._mailInRebates];

      if (dayjs(cartItem._arrivesDateTime).isAfter(inventoryArrivesDateTime)) {
        inventoryArrivesDateTime = cartItem._arrivesDateTime;
      }

      return cartItem;
    });

    const clientPayload = cartItems.map(
      ({
        cartItemTypeId,
        brandEndPoint,
        partNumberEndPoint,
        _cartQty,
        webUniqueItemId,
      }) => {
        let cookieItem = {};

        if (cartItemTypeId == CART_ITEM_TYPE.tire) {
          cookieItem = {
            cartItemTypeId,
            brandEndPoint,
            partNumberEndPoint,
            _cartQty,
          };
        } else {
          cookieItem = {
            cartItemTypeId,
            webUniqueItemId,
          };
        }

        return cookieItem;
      }
    );

    CLIENT_STORAGE.cart.setItem(clientPayload);

    const hasTiresinCart = cartItems.some(
      ({ cartItemTypeId }) => cartItemTypeId == CART_ITEM_TYPE.tire
    );

    const isInventoryInStock = cartItems.every(
      ({ _qtyIsInStock }) => _qtyIsInStock
    );

    const isInventoryAvailable = cartItems.every(
      ({ _qtyIsAvailable }) => _qtyIsAvailable
    );

    const requiresSpecialOrder = cartItems.some(
      (item) => !item.isPreferredBrand && item._requiresOutsideVendor
    );

    set((prevState) => {
      if (prevState.isReady && cartItems.length > prevState.cartItems.length) {
        const newItem = cartItems[cartItems.length - 1];
        const newItemValue = newItem._invoicePrice;
        GoogleEventsStore.getState().sendAddToCartEvent(newItemValue, newItem);
      }

      return {
        ...prevState,
        packageSubtotal,
        totalDiscountsAvailable,
        instantDiscountTotal,
        mailInRebateTotal,
        mailInRebates,
        subtotal,
        tax,
        stfTotal,
        fetTotal,
        taxAndFeesTotal: roundMoney(tax + stfTotal + fetTotal),
        invoicePrice,
        cartItems,
        hasTiresinCart,
        isInventoryInStock,
        isInventoryAvailable,
        inventoryArrivesDateTime,
        inventoryArrivesDateTimeDisplay: userStore.useCalendarDisplay(
          inventoryArrivesDateTime,
          {
            sameDay: "[Today -] dddd, MMM D [at] h:mm A",
            nextDay: "[Tomorrow -] dddd, MMM D [at] h:mm A",
            nextWeek: "dddd, MMM D [at] h:mm A",
            sameElse: "dddd, MMM D [at] h:mm A",
          }
        ),
        requiresSpecialOrder,
      };
    });
  };

  const getEdgeCartSummary = () => {
    const {
      isInventoryInStock,
      isInventoryAvailable,
      inventoryArrivesDateTime,
      inventoryArrivesDateTimeDisplay,
      tax,
      fetTotal,
      invoicePrice,
    } = get();

    return {
      IsInventoryInStock: isInventoryInStock,
      IsInventoryAvailable: isInventoryAvailable,
      InventoryArrivesDateTime: inventoryArrivesDateTime,
      InventoryArrivesDateTimeDisplay: inventoryArrivesDateTimeDisplay,
      Subtotal: roundMoney(invoicePrice - tax),
      Tax: tax,
      FET: fetTotal,
      ShopSupplies: 0,
      TotalPrice: invoicePrice,
    };
  };

  const getEdgeCartDetails = () => {
    const { cartItems } = get();

    const EDGE_ITEM_TYPES = {
      tire: "T",
      service: "S",
      inventory: "I",
      fee: "F",
      package: "P",
    };

    const createEdgeCartItem = ({
      isParentItem = true,
      parentId,
      itemId = EDGE_ITEM_IDS.uci,
      description = "",
      edgeItemType = EDGE_ITEM_TYPES.service,
      qty = 1,
      price = 0,
      itemSubtotal = 0,
      itemTax = 0,
    }) => ({
      IsParentItem: isParentItem,
      ParentId: parentId,
      ItemId: itemId,
      Description: description,
      EdgeItemType: edgeItemType,
      Qty: qty,
      Price: price,
      ItemSubtotal: itemSubtotal,
      ItemTax: itemTax,
    });

    let edgeCartItems = cartItems.map((cartItem, index) => {
      const parentId = index + 1;

      let edgeCartItem = createEdgeCartItem({
        parentId,
        itemId: cartItem.edgeItemId,
        description: cartItem.edgeDescription,
        qty: cartItem?._cartQty || 0,
        price: cartItem?.price || 0,
        itemSubtotal: cartItem?._subtotal || 0,
        itemTax: cartItem?._tax || 0,
      });

      if (cartItem.cartItemTypeId == CART_ITEM_TYPE.tire) {
        const tire = cartItem;

        const { _cartQty } = tire;

        const edgeTireItem = createEdgeCartItem({
          isParentItem: true,
          parentId,
          itemId: tire.itemId,
          description: `${tire.brandName} ${tire.itemModelName} ${tire.tireSizeDisplay} ${tire.loadIndexSpeedRatingDisplay}`,
          edgeItemType: EDGE_ITEM_TYPES.tire,
          qty: _cartQty,
          price: tire.price,
          itemSubtotal: tire._tiresSubtotal,
          itemTax: tire._tiresTax,
        });

        const edgeTireInstall = tire._installPackage.map((installItem) => {
          return createEdgeCartItem({
            isParentItem: false,
            parentId,
            itemId: installItem.itemId,
            description:
              installItem.itemId !== EDGE_ITEM_IDS.labas
                ? installItem.description
                : "VEHICLE ALIGNMENT - STANDARD (2 OR 4 WHEEL)",
            edgeItemType: installItem.edgeItemType,
            qty: installItem.itemId !== EDGE_ITEM_IDS.labas ? _cartQty : 1,
            price: installItem.price,
            itemSubtotal: installItem.itemSubtotal,
            itemTax: installItem.itemTax,
          });
        });

        const { tireSTF } = LocationStore.getState().userStore;

        const edgeTireSTF =
          tireSTF &&
          createEdgeCartItem({
            isParentItem: false,
            parentId,
            itemId: tireSTF.itemId,
            description: tireSTF.description,
            edgeItemType: EDGE_ITEM_TYPES.fee,
            qty: _cartQty,
            price: roundMoney(tire._stfTotal / _cartQty),
            itemSubtotal: tire._stfTotal,
          });

        let edgeInstantDiscount;

        if (tire._instantRebateDiscountsTotal > 0) {
          edgeInstantDiscount = createEdgeCartItem({
            isParentItem: false,
            parentId,
            itemId: EDGE_ITEM_IDS.tireInstantDiscount,
            description: `${tire._promotion.displayText.tireSaleName} - INSTANT TIRE SAVINGS`,
            qty: -1 * _cartQty,
            price: roundMoney(tire._instantRebateDiscountsTotal / _cartQty),
            itemSubtotal: roundMoney(-1 * tire._instantRebateDiscountsTotal),
          });

          if (["gen_b3g1", "col_b3g1"].includes(tire._promotion.promoType)) {
            edgeInstantDiscount = {
              ...edgeInstantDiscount,
              itemId:
                tire._promotion.edgeItemId || EDGE_ITEM_IDS.tireInstantDiscount,
              description: `${tire._promotion.displayText.tireSaleName} - BUY 3, GET 1 FREE W/ PURCHASE OF TIRE MAINT. CERTIFICATES`,
              qty: -1,
              price: tire._instantRebateDiscountsTotal,
              itemSubtotal: tire._instantRebateDiscountsTotal,
            };
          }

          if (tire._promotion.promoType == "focus_b2g2") {
            edgeInstantDiscount = {
              ...edgeInstantDiscount,
              itemId:
                tire._promotion.edgeItemId || EDGE_ITEM_IDS.tireInstantDiscount,
              description: `${tire._promotion.displayText.tireSaleName} - BUY 2, GET 2 FREE W/ PURCHASE OF TIRE MAINT. CERTIFICATES & WHEEL ALIGNMENT (Up to $300)`,
              qty: -1,
              price: tire._instantRebateDiscountsTotal,
              itemSubtotal: tire._instantRebateDiscountsTotal,
            };
          }

          if (tire._promotion.promoType == "d18_b2g2") {
            edgeInstantDiscount = {
              ...edgeInstantDiscount,
              itemId:
                tire._promotion.edgeItemId || EDGE_ITEM_IDS.tireInstantDiscount,
              description: `${tire._promotion.displayText.tireSaleName} - BUY 2, GET 2 FREE W/ PURCHASE OF TIRE MAINT. CERTIFICATES (Up to $300)`,
              qty: -1,
              price: tire._instantRebateDiscountsTotal,
              itemSubtotal: tire._instantRebateDiscountsTotal,
            };
          }
        }

        let edgeMailInRebates;
        if (tire._mailInRebates.length) {
          edgeMailInRebates = tire._mailInRebates.map((rebate) => {
            return createEdgeCartItem({
              isParentItem: false,
              parentId,
              description: `Mail-In Rebate - ${rebate.rebateDescription}. Visit ${APP_ENV.appSlug}/coupons for rebate details.`,
            });
          });
        }

        let edgeTireInventoryFufillmentMessage =
          "**Tire Order Fulfillment Details: ";

        if (tire._fulfillmentDetails.length) {
          const deliveryOptionsList = tire._fulfillmentDetails.reduce(
            (prev, opt, i) => {
              prev += opt.isInStock ? "In Stock" : opt.orderFrom;
              prev += ` Qty: ${opt.qtyCount}`;

              if (i < tire._fulfillmentDetails.length - 1) {
                prev += `${` | `}`;
              }
              return prev;
            },
            ""
          );
          edgeTireInventoryFufillmentMessage += deliveryOptionsList;
        } else {
          edgeTireInventoryFufillmentMessage +=
            "Inventory availability unknown";
        }

        const edgeInvTransferDetails = createEdgeCartItem({
          isParentItem: false,
          parentId,
          description: `${edgeTireInventoryFufillmentMessage} **`,
        });

        edgeCartItem = [
          edgeTireItem,
          edgeTireInstall,
          edgeTireSTF,
          edgeInstantDiscount,
          edgeMailInRebates,
          edgeInvTransferDetails,
        ]
          .filter((i) => i)
          .flat();
      }

      if (cartItem.cartItemTypeId == CART_ITEM_TYPE.smogCheck) {
        const smogCheckPackage = createEdgeCartItem({
          isParentItem: true,
          parentId,
          itemId: SMOG_CHECK_CONFIG.package.edgeItemId,
          description: "WEB PACKAGE - SMOG CHECK SERVICE - NEVADA",
          edgeItemType: EDGE_ITEM_TYPES.package,
          qty: 1,
          price: 0,
          itemSubtotal: 0,
          itemTax: 0,
        });

        const smogCheckLabor = createEdgeCartItem({
          isParentItem: false,
          parentId,
          itemId: SMOG_CHECK_CONFIG.labor.edgeItemId,
          description: "LABOR - SMOG CHECK",
          edgeItemType: EDGE_ITEM_TYPES.service,
          qty: 1,
          price: SMOG_CHECK_CONFIG.labor.price,
          itemSubtotal: SMOG_CHECK_CONFIG.labor.price,
          itemTax: 0,
        });
        const smogCheckCertificate = createEdgeCartItem({
          isParentItem: false,
          parentId,
          itemId: SMOG_CHECK_CONFIG.certificate.edgeItemId,
          description: "SMOG CERTIFICATE - NEVADA",
          edgeItemType: EDGE_ITEM_TYPES.service,
          qty: 1,
          price: SMOG_CHECK_CONFIG.certificate.price,
          itemSubtotal: SMOG_CHECK_CONFIG.certificate.price,
          itemTax: 0,
        });

        edgeCartItem = [smogCheckPackage, smogCheckLabor, smogCheckCertificate];
      }

      if (
        Object.values(STATIC_CART_ITEMS)
          .filter(({ promotion }) => promotion)
          .some(
            ({ webUniqueItemId }) => webUniqueItemId == cartItem.webUniqueItemId
          )
      ) {
        edgeCartItem = {
          ...edgeCartItem,
          Description: `${edgeCartItem.Description} | ${cartItem.promotion.edgeDescription}`,
        };
      }

      if (
        [EDGE_ITEM_IDS.silverRockAppt, EDGE_ITEM_IDS.shieldNetAppt].includes(
          cartItem.edgeItemId
        )
      ) {
        const WARRANTY_NAMES = {
          [EDGE_ITEM_IDS.silverRockAppt]: "SilverRock",
          [EDGE_ITEM_IDS.shieldNetAppt]: "Shield Repair Network",
        };

        edgeCartItem = [
          edgeCartItem,
          createEdgeCartItem({
            isParentItem: false,
            parentId,
            description: `**STORE USE ONLY: For instructions on ${
              WARRANTY_NAMES[cartItem.edgeItemId]
            } Warranty claims, please call Ken Mcqueen at the support center.**`,
          }),
        ];
      }

      return edgeCartItem;
    });

    edgeCartItems = [...edgeCartItems.flat(Infinity)];

    //Invoice notes uci line
    const { userAppointment } = AppointmentStore.getState();

    const {
      isInventoryInStock,
      isInventoryAvailable,
      inventoryArrivesDateTimeDisplay,
    } = get();

    const { userApptNotes = null } = UserInfoStore.getState();

    const apptType =
      userAppointment.apptTypeId == WEB_APPOINTMENT_TYPES.dropOff
        ? "DROP OFF"
        : "WAITING";
    const apptMessage = `Appointment: ${apptType} - ${userAppointment.apptDateTimeDisplay}`;

    let inventoryMessage = "";
    switch (true) {
      case isInventoryInStock:
        inventoryMessage = "In Stock";
        break;
      case isInventoryAvailable:
        inventoryMessage = `Local Order/Transfer. Inventory available ${inventoryArrivesDateTimeDisplay}.`;
        break;
      default:
        inventoryMessage = `Arrival date unknown for item(s) in your cart. Please call for availability.`;
    }

    const edgeApptDetails = createEdgeCartItem({
      parentId:
        Math.max(
          ...[...new Set(edgeCartItems.map(({ ParentId }) => ParentId))]
        ) + 1,
      description: `${apptMessage} | Inventory Status: ${inventoryMessage}${
        userApptNotes
          ? ` | IMPORTANT - Guest Appointment Notes: ${userApptNotes}`
          : ""
      }`,
    });

    edgeCartItems = [...edgeCartItems, edgeApptDetails];

    return edgeCartItems;
  };

  return {
    ...initialState,
    getCartStoreAsync,
    useCartItemActions: () => {
      return [() => get().cartItems, setCartItems];
    },
    clearCartStore: () => {
      CLIENT_STORAGE.cart.removeItem();
      set(initialState);
    },
    getEdgeCartSummary,
    getEdgeCartDetails,
  };
});

export const useCartStore = (selector = (store) => store) => {
  const [getCartStoreAsync] = CartStore((st) => [st.getCartStoreAsync]);

  const [sessionId] = SessionTimerStore((st) => [st.sessionId]);
  const [storeNumber] = useLocationStore((st) => [st.userStore?.storeNumber]);

  useEffect(() => {
    if (_sessionId !== sessionId || _storeNumber !== storeNumber) {
      getCartStoreAsync();
    }
  }, [sessionId, storeNumber]);

  return CartStore(selector);
};

export default CartStore;
