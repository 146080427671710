import { Box, Button, Link, Typography, Paper } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import StoreMallDirectoryRoundedIcon from "@material-ui/icons/StoreMallDirectoryRounded";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { StandardWidthContainer } from "../../generic/containers";
import UserLocationPopover from "../../locations/UserLocationPopover";
import VehicleDisplayNameWithDesktopModal from "../../reusable/user-vehicle/VehicleDisplayNameWithDesktopModal";
import ModalStore from "../../../hooks/store/modal-store";
import useOpenTireSizeSelector from "../../../hooks/util/useOpenTireSizeSelector";
import CartIcon from "../cart-icon";
import {
  CAR_SERVICES_ROUTE_DIRECTORY,
  QUICK_APPOINTMENT_DIRECTORY,
} from "../../../routes";
import { useLocationStore } from "../../../hooks/store/location-store";
import APP_ENV from "../../../appEnvironment";
import { CREDIT_PAGE_ENDPOINT } from "../../../hooks/store/credit-store";
import OlarkChat from "../../../hooks/store/client/olark-chat";

const { theme } = APP_ENV;

const INFO_BAR_H = 40;
const STORE_VEHICLE_H = 100;
const MENU_LINKS_H = INFO_BAR_H;

export const DESKTOP_HEADER_HEIGHT =
  STORE_VEHICLE_H + INFO_BAR_H + MENU_LINKS_H;

const MainHeaderDesktop = () => {
  const openTireSizeModal = useOpenTireSizeSelector({ pushToTiresPage: true });
  const { closeAllModals } = ModalStore();

  const LOGO_CONT_W = 300;
  const CART_CONT_W = 100;

  const [userStore] = useLocationStore((st) => [st.userStore]);

  return (
    <Paper elevation={5}>
      <StandardWidthContainer backgroundColor={theme.palette.grey[300]}>
        <Box
          style={{
            height: INFO_BAR_H,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link
            href={`tel:${APP_ENV.appPhoneNumber}`}
            style={{ fontWeight: "bold" }}
          >
            {APP_ENV.appPhoneNumber}
          </Link>
          <Box style={{ display: "flex" }}>
            {[
              ["Live Chat", { onClick: OlarkChat.expand }],
              ["About Us", { href: "/about-us" }],
              ["Careers", { href: "/careers" }],
              ["Credit", { href: CREDIT_PAGE_ENDPOINT }],
              ["Customer Support", { href: "/contact" }],
            ].map(([label, { href = "#", onClick = () => null }], i) => {
              const borderRight =
                i !== 4 ? `1px solid ${theme.palette.divider}` : "";
              return (
                <Box
                  key={i}
                  style={{
                    borderRight,
                    paddingRight: 15,
                    paddingLeft: 15,
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={href}
                    onClick={onClick}
                    style={{
                      fontSize: 12,
                      color: theme.palette.common.black,
                      fontWeight: 600,
                    }}
                  >
                    {label}
                  </Link>
                </Box>
              );
            })}
          </Box>
        </Box>
      </StandardWidthContainer>
      <StandardWidthContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: STORE_VEHICLE_H + INFO_BAR_H,
          }}
        >
          <Box
            style={{
              width: LOGO_CONT_W,
              height: "100%",
              padding: 15,
              paddingLeft: 0,
              display: "flex",

              alignItems: "center",
            }}
          >
            <RouterLink to="/" onClick={closeAllModals}>
              <img
                style={{
                  height: "auto",
                  width: "100%",
                  objectFit: "contain",
                }}
                src={APP_ENV.appBrandLogoImg}
                alt={`${APP_ENV.appBrandName} logo`}
              />
            </RouterLink>
          </Box>
          <Box
            style={{
              width: `calc(100% - ${LOGO_CONT_W}px)`,
              height: "100%",
            }}
          >
            <Box
              style={{
                width: "100%",
                height: STORE_VEHICLE_H,
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 8,
                paddingBottom: 8,
              }}
            >
              <Box
                style={{
                  width: `calc(100% - ${CART_CONT_W}px)`,
                  maxWidth: 750,
                  height: "100%",
                  display: "flex",
                }}
              >
                <Box
                  style={{
                    height: "100%",
                    width: "50%",
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: 8,
                    }}
                  >
                    <StoreMallDirectoryRoundedIcon
                      style={{
                        marginRight: 8,
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography
                      style={{ fontSize: 14, fontWeight: 600 }}
                      noWrap
                    >
                      Your Store
                    </Typography>
                  </Box>
                  <Box style={{ width: "100%", paddingLeft: 5 }}>
                    <UserLocationPopover />
                  </Box>
                </Box>
                <Box
                  style={{
                    height: "100%",
                    width: "50%",
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    paddingLeft: 20,
                    paddingRight: 10,
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: 8,
                    }}
                  >
                    <DirectionsCarIcon
                      style={{
                        marginRight: 8,
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography
                      style={{ fontSize: 14, fontWeight: 600 }}
                      noWrap
                    >
                      Your Vehicle
                    </Typography>
                  </Box>
                  <Box style={{ width: "100%", paddingLeft: 5 }}>
                    <VehicleDisplayNameWithDesktopModal />
                  </Box>
                </Box>
              </Box>

              <Box
                style={{
                  width: CART_CONT_W,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CartIcon />
              </Box>
            </Box>

            <Box
              style={{
                width: "100%",
                height: MENU_LINKS_H,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderTop: `1px solid ${theme.palette.divider}`,
              }}
            >
              {[
                ["Tires", { onClick: openTireSizeModal }],
                ["Oil Change", { href: "/oilrecommendations" }],
                !userStore?.smogCheck?.endPoint
                  ? ["Wheels", { href: "/wheels" }]
                  : ["Smog Check", { href: userStore.smogCheck.endPoint }],
                ["Services", { href: CAR_SERVICES_ROUTE_DIRECTORY }],
                ["Locations", { href: "/locations" }],
                ["Hot Deals", { href: "/coupons" }],
                ["Quick Appointment", { href: QUICK_APPOINTMENT_DIRECTORY }],
              ]
              .filter(([label]) => !Boolean(APP_ENV.appBrandId === 4 && label == "Oil Change"))
                .map(([label, { onClick = () => null, href = "#" }], i) => {
                  return (
                    <Button
                      component={RouterLink}
                      to={href}
                      onClick={onClick}
                      key={i}
                      style={{ fontSize: `min(1.3vw, 15px)` }}
                    >
                      {label}
                    </Button>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </StandardWidthContainer>
    </Paper>
  );
};

export default MainHeaderDesktop;
