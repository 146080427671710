import create from "zustand";
import TireSearchFilterStore from "./tire-search-results/tire-search-filter-store";

const LowCostCustomerStore = create((set, get) => {
  const initialState = {
    isLowCostCustomer: false,
    tirePriceRanks: [],
    tireAvailabilityRank: [],
  };

  const getActiveTiresRank = async () => {
    const { filteredTires } = await TireSearchFilterStore.getState();
    const activeTires = filteredTires;

    const rankedTires = activeTires.slice().sort((a, b) => a.price - b.price);

    set({ tirePriceRanks: rankedTires });
  };

  return {
    ...initialState,
    getActiveTiresRank,
  };
});

export default LowCostCustomerStore;
