import * as React from "react";

const TestPage = () => {
  const idk = Boolean(0) && "test";

  console.log({ idk });

  return <></>;
};

export default TestPage;
