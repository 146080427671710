import APP_ENV from "../../appEnvironment";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import NightsStayIcon from "@material-ui/icons/NightsStay";
/*


 */

const getServiceTileImage = (appBrandId) => {
  switch (appBrandId) {
    case 1:
      return "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/bfg-hero-halloween-updated.jpg";
    case 2:
      return "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/bfg-hero-halloween-updated.jpg";
    case 3:
      return "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/bfg-hero-halloween-updated.jpg";
    case 4:
      return "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/wheel-package_hero-image.jpg";
  }
};

const getGenericImages = (appBrandId) => {
  switch (appBrandId) {
    case 1:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Nexen-tire_b3g1_hero_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Nexen-tire_b3g1_tire-look-up_halloween.jpg",
      };
    case 2:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Nexen-tire_b3g1_hero_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Nexen-tire_b3g1_tire-look-up_halloween.jpg",
      };
    case 3:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Nexen-tire_b3g1_hero_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Nexen-tire_b3g1_tire-look-up_halloween.jpg",
      };
    case 4:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/Final_tty-hero-image-halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/final_TTY_tire-look-up-halloween.jpg",
      };
  }
};

const getColoradoImages = (appBrandId) => {
  switch (appBrandId) {
    case 1:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_hero%20sum%20&%20nex_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_tire-look-up_sum-&-nex_halloween.jpg",
      };
    case 2:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_hero%20sum%20&%20nex_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_tire-look-up_sum-&-nex_halloween.jpg",
      };
    case 3:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_hero%20sum%20&%20nex_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_tire-look-up_sum-&-nex_halloween.jpg",
      };
    case 4:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B3G1_hero_labor-day-sale-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b3g1_tire-look-up_sum-nex_labor-day-2024.jpg",
      };
  }
};

const getFocusImages = (appBrandId) => {
  switch (appBrandId) {
    case 1:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B2G2_hero-image_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B2G2_tire-look-up_halloween.jpg",
      };
    case 2:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B2G2_hero-image_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B2G2_tire-look-up_halloween.jpg",
      };
    case 3:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B2G2_hero-image_halloween.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/B2G2_tire-look-up_halloween.jpg",
      };
    case 4:
      return {
        main: "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_hero_labor-day-sale-2024.jpg",
        secondary:
          "https://venomstorage.blob.core.windows.net/bbtwebblob/promos/b2g2_tire-look-up_labor-day-2024.jpg",
      };
  }
};

export const PROMO_THEME = (() => {
  const promoTheme = {
    sitePromoName: "Spooktacular Sale",
    sitePromoColors: {
      primary: "#262878",
      secondary: "#4AB8BD",
      tertiary: "#DD0000",
    },
    SitePromoIcon: NightsStayIcon,
    serviceTileImage: getServiceTileImage(APP_ENV.appBrandId),
  };

  if (APP_ENV.appBrandId == 4) {
    // promoTheme.sitePromoName = "Get up to $260 back with Pirelli tires!";
    // promoTheme.sitePromoColors = {
    //   primary: "#DD0000",
    //   secondary: "#374089",
    //   tertiary: "#DD0000",
    // };
    // promoTheme.SitePromoIcon = LocalOfferIcon;
    promoTheme.serviceTileImage = getServiceTileImage(APP_ENV.appBrandId);
  }

  return promoTheme;
})();

const PROMO_OPTIONS = {
  generic: {
    promoType: "gen_b3g1",
    brandEndPoints: ["nexen"],
    edgeItemId: 104104,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 3, Get 1 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 3, Get 1 FREE!*",
    },
    images: getGenericImages(APP_ENV.appBrandId),
  },
  colorado: {
    promoType: "col_b3g1",
    brandEndPoints: ["summit", "nexen"],
    edgeItemId: 103000,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 3, Get 1 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 3, Get 1 FREE!*",
    },
    images: getColoradoImages(APP_ENV.appBrandId),
  },
  focus: {
    promoType: "focus_b2g2",
    brandEndPoints: ["summit", "nexen"],
    edgeItemId: 103820,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Buy 2, Get 2 FREE!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}!`,
      tirePromoBox: "Buy 2, Get 2 FREE!*",
    },
    images: getFocusImages(APP_ENV.appBrandId),
  },
  texas: {
    promoType: "bf-goodrich",
    brandEndPoints: ["bf-goodrich"],
    edgeItemId: null,
    displayText: {
      alertBar: `${PROMO_THEME.sitePromoName} - Up to $220 back on BFGoodrich tires!`,
      tireSaleName: `${PROMO_THEME.sitePromoName}`,
      tirePromoBox: "Up to $220 back ",
    },
    images: getGenericImages(APP_ENV.appBrandId),
  },
};

const PROMO_STORE_GROUPS = {
  tucson: [
    "3020",
    "3025",
    "3026",
    "3027",
    "3028",
    "3029",
    "3030",
    "3031",
    "3032",
    "3033",
    "3034",
    "3035",
  ],
  otherAz: [
    "3001",
    "3002",
    "3003",
    "3004",
    "3005",
    "3006",
    "3007",
    "3008",
    "3009",
    "3010",
    "3011",
    "3012",
    "3013",
    "3014",
    "3015",
    "3016",
    "3017",
    "3018",
    "3019",
    "3021",
    "3022",
    "3023",
    "3024",
    "3036",
    "3037",
  ],
  vegas: [
    "3203",
    "3204",
    "3205",
    "3206",
    "3207",
    "3208",
    "3209",
    "3210",
    "3211",
    "3212",
    "3213",
    "3214",
  ],
  denver: ["2007", "2008", "2009", "2010", "2011", "2012", "2013"],
  reno: ["3200", "3201", "3202", "1051"],
  texas: ["4001", "4002", "4003", "4004", "4005", "4006", "4007"],
};

export const getPromotionByLocation = ({ storeNumber = null }) => {
  let promotion = PROMO_OPTIONS.generic;

  if (APP_ENV.appBrandId == 2) {
    promotion = PROMO_OPTIONS.colorado;
  }

  if (
    [
      ...PROMO_STORE_GROUPS.tucson,
      ...PROMO_STORE_GROUPS.otherAz,
      ...PROMO_STORE_GROUPS.vegas,
      ...PROMO_STORE_GROUPS.denver,
      ...PROMO_STORE_GROUPS.reno,
    ].includes(storeNumber)
  ) {
    promotion = PROMO_OPTIONS.focus;
  }

  if (APP_ENV.appBrandId == 4) {
    promotion = PROMO_OPTIONS.texas;
  }

  return {
    ...PROMO_THEME,
    ...promotion,
  };
};
